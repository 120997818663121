import './Test02.css'
import React from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function Test02() {
    

    return (
        <div className="container-sticy-element">
            <div className="left">
                <h1>Fixed Left Side</h1>
            </div>
            <div className="right">
                <div className="content">
                    <p>Content 1</p>
                    <p>Content 2</p>
                    <p>Content 3</p>
                </div>
            </div>
        </div>
    );
}

    
export default Test02
