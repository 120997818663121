import React, { useEffect, useRef, useState } from 'react';
import './Plus.css';
import SwipeSection3 from './SwipeSection3';
import Test02 from './Test02';
// import FlightMain from './FilightComponent/FlightMain';
import Footer from './Footer';
// import { PlayProvider } from "./FilightComponent/contexts/Play";

// AOS
import Aos from 'aos';
import 'aos/dist/aos.css';

const PlusSign = () => {
  const horizontalContainerRef = useRef(null);
  const [newHeight, setNewHeight] = useState(0);
  const [newRotationDegree, setNewRotationDegree] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [marginLeft, setMarginLeft] = useState(-870);
  // const [newBoxTop, setNewBoxTop] = useState(69);
  const flightAnimationRef = useRef(null);
  const footerRef = useRef(null);
  const lastScrollTopRef = useRef(0);

  useEffect(() => {
    Aos.init({
      duration: 1500,
      easing: 'ease',
      once: true
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY || document.documentElement.scrollTop;

      if (currentScrollTop < 800) {
        setScrollPosition(currentScrollTop);
      } else {
        setScrollPosition(801);
      }

      if (currentScrollTop > 805) {
        setMarginLeft(currentScrollTop - 1675);
      } else {
        setMarginLeft(-870);
      }

      // Check scroll direction
      const isScrollingDown = currentScrollTop > lastScrollTopRef.current;
      lastScrollTopRef.current = currentScrollTop; // Update last scroll position

      // If scrolling down and in the right section, trigger smooth scroll
      // if (isScrollingDown && flightAnimationRef.current) {
      //   const rect = flightAnimationRef.current.getBoundingClientRect();
      //   const viewportHeight = window.innerHeight;

      //   if (rect.top <= viewportHeight && rect.bottom >= viewportHeight) {
      //     window.scrollTo({
      //       top: flightAnimationRef.current.offsetTop,
      //       behavior: 'smooth'
      //     });
      //   }
      // }
      
      if (isScrollingDown && footerRef.current) {
        const rect = footerRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        if (rect.top  + 40<= viewportHeight && rect.bottom >= viewportHeight) {
          window.scrollTo({
            top: footerRef.current.offsetTop,
            behavior: 'smooth'
          });
        }
      }

      // if (!isScrollingDown && footerRef.current) {
      //   const rect = footerRef.current.getBoundingClientRect();
      //   const viewportHeight = window.innerHeight;

      //   if (rect.top <= viewportHeight && rect.bottom >= viewportHeight) {
      //     window.scrollTo({
      //       top: flightAnimationRef.current.offsetTop,
      //       behavior: 'smooth'
      //     });
      //   }
      // }
    };

    const height = 100;
    if(scrollPosition === 0){
      setNewHeight(45);
      setNewRotationDegree(0);
    }else if(scrollPosition <= 100){
      setNewHeight(100);
      setNewRotationDegree(scrollPosition / 10)
      setMarginLeft(-800);
    }else{
      setNewHeight(height - scrollPosition / 10);
      setNewRotationDegree(scrollPosition / 5)
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition, marginLeft]);

  useEffect(() => {
    if (marginLeft >= 0 && marginLeft < 15) {
      document.body.classList.add('no-scroll');
      setTimeout(() => {
        document.body.classList.remove('no-scroll');
      }, 1500);
    }
  }, [marginLeft]);

  useEffect(() => {
    const handleHorizontalScroll = (event) => {
      const horizontalContainer = horizontalContainerRef.current;
      const verticalScrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (horizontalContainer && verticalScrollTop >= horizontalContainer.offsetTop && verticalScrollTop < horizontalContainer.offsetTop + horizontalContainer.offsetHeight) {
        event.preventDefault();
        horizontalContainer.scrollLeft += event.deltaY;
      }
    };

    window.addEventListener('scroll', handleHorizontalScroll, { passive: false });

    return () => {
      window.removeEventListener('scroll', handleHorizontalScroll);
    };
  }, []);

  // New calculations  
  const newBoxTop = 58;
  const left = (newHeight - 20) / 2;
  const newBoxTopVariable = newBoxTop - scrollPosition / 50;

  return (
    <div className="perent-conatiner">
      <div className="parent-content-container parent-content-container-3">
        <div className="parent-content-container-left" data-aos='fade-right' data-aos-duration='1000'>
          <h1>Bringing good<br />things to life</h1>
        </div>
        <div className="parent-content-container-right" data-aos='fade-up' data-aos-duration='1000'>
          <h1>Sunshine Holdings is a leading Sri Lankan conglomerate dedicated to sustainable growth and positive social impact. With a diverse portfolio spanning agriculture, healthcare, and consumer goods, The company prioritizes diversity, equality, and inclusivity in its operations.</h1>
        </div>
      </div>

      <div className='new-box'
        
        style={{
          transform: `rotate(-${scrollPosition >= 800 ? 180 : newRotationDegree}deg)`,
          width: `${marginLeft >= 0 ? newHeight + 20 : newHeight}px`,
          height: `${newHeight}px`,
          top: `${newBoxTopVariable <= 40 ? 40 : newBoxTopVariable}%`,
          zIndex: '100',
          marginLeft: `${marginLeft >= 0 ? 0 : marginLeft}px`,
          border: `${marginLeft >= 0 ? '2.5px solid darkgray' : 'none'}`,
          scale: `${marginLeft >= 0 ? 80 : 1}`,
          background:`${marginLeft >= 0 ? 'white' : 'transparent'}`,
          transition : `${scrollPosition <= 100 ? '0.5s ease' : ''}`
        }}
      >
        <div className='new-box-horizontel' style={{ height: `${newHeight <= 20 ? 20 : newHeight}px`, left: `${left <= 0 ? 0 : left}px`, display: `${marginLeft >= 0 ? 'none' : 'block'}`, transition : `${scrollPosition <= 100 ? '0.5s ease' : 'none'}` }}></div>
        <div className='new-box-vertical' style={{ width: `${newHeight <= 20 ? 40 : newHeight}px`, top: `${left <= 0 ? 0 : left}px`, display: `${marginLeft >= 0 ? 'none' : 'block'}`, transition : `${scrollPosition <= 100 ? '0.5s ease' : 'none'}`, }}></div>
      </div>

      {marginLeft >= 0 &&
        <div className='new-divi'>
          <div className='new-divi-2'>
            <div className="actual-content">
              <SwipeSection3 />
              <div className="parent-content-container parent-content-container-2">
                <div className="parent-content-container-right parent-content-container-right-2" data-aos='fade-up' data-aos-duration='1000' data-aos-offset='-1000'>
                  <h1>Sunshine Holdings is a leading Sri Lankan conglomerate dedicated to sustainable growth and positive social impact. With a diverse portfolio spanning agriculture, healthcare, and consumer goods, The company prioritizes diversity, equality, and inclusivity in its operations.</h1>
                </div>
                <div className="parent-content-container-left parent-content-container-left-2" data-aos='fade-left' data-aos-duration='1000' data-aos-offset='-1000'>
                  <h1>Bringing good<br />things to life</h1>
                </div>
              </div>
              <Test02 />
              <div className="parent-content-container parent-content-container-2">
                <div className="parent-content-container-right parent-content-container-right-2" data-aos='fade-up' data-aos-duration='1000' data-aos-offset='-1000'>
                  <h1>Sunshine Holdings is a leading Sri Lankan conglomerate dedicated to sustainable growth and positive social impact. With a diverse portfolio spanning agriculture, healthcare, and consumer goods, The company prioritizes diversity, equality, and inclusivity in its operations.</h1>
                </div>
                <div className="parent-content-container-left parent-content-container-left-2" data-aos='fade-left' data-aos-duration='1000' data-aos-offset='-1000'>
                  <h1>Bringing good<br />things to life</h1>
                </div>
              </div>

              <div className='flight-animation-main-container' ref={flightAnimationRef}>
                {/* <PlayProvider>
                  <FlightMain />
                </PlayProvider> */}
              </div>

              <div ref={footerRef}>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default PlusSign;
