import HomeBanner from "./Components/HomeBanner";
import Plus from './Components/Plus';
// import SwipeSection3 from './Components/SwipeSection3';
// import Flight from './Components/FilightComponent/FlightMain'


function App() {
  
  return (
    <div className="App">
      <HomeBanner />
      <Plus/>
      {/* <SwipeSection3/> */}
      {/* <Flight /> */}
    </div>
  );
}

export default App;
