import React, {
  useRef,
  useState,
  useLayoutEffect,
  useCallback,
  useEffect
} from "react";
import ResizeObserver from "resize-observer-polyfill";
import {
  motion,
  useScroll,
  useTransform,
  useSpring,
  useMotionValue
} from "framer-motion";
import './SwipeSection3.css';
import PlaceHolder2 from '../assest/place-holder-3.webp'

import { useScrollPercentage } from "react-scroll-percentage";

const SmoothScroll = () => {
  const scrollRef = useRef(null);
  const ghostRef = useRef(null);
  const [scrollRange, setScrollRange] = useState(0);
  const [viewportW, setViewportW] = useState(0);
  const [sectionWidth, setSectionWidth] = useState(0);

  const scrollPerc = useMotionValue(0);

  useLayoutEffect(() => {
    if (scrollRef.current) {
      const totalWidth = scrollRef.current.scrollWidth;
      setScrollRange(totalWidth);
      setSectionWidth(totalWidth / 6); // Assuming there are 6 sections now
    }
  }, [scrollRef]);

  const onResize = useCallback((entries) => {
    for (let entry of entries) {
      setViewportW(entry.contentRect.width);
    }
  }, []);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => onResize(entries));
    resizeObserver.observe(ghostRef.current);
    return () => resizeObserver.disconnect();
  }, [onResize]);

  const { scrollYProgress } = useScroll(1000);

  const [containerRef, percentage] = useScrollPercentage({
    /* Optional options */
    threshold: 0.1
  });

  useEffect(() => {
    scrollPerc.set(percentage);
  }, [scrollPerc, percentage]);

  const transform = useTransform(
    scrollYProgress,
    [0, 1/5, 2 / 8, 3 / 8, 4 / 8, 5 / 8, 6/8 , 1 ,1],
    [0, 0, -sectionWidth, -sectionWidth * 2, -sectionWidth * 3, -sectionWidth * 4, -sectionWidth * 5,-sectionWidth * 5.5, -sectionWidth * 6  ]
  );

  const physics = { damping: 15, mass: 0.27, stiffness: 55 };
  const spring = useSpring(transform, physics);

  return (
    <div ref={containerRef}>
      <div className="scroll-container">
        <motion.section
          ref={scrollRef}
          style={{ x: spring }}
          className="thumbnails-container"
        >
          <div className="thumbnails">
            <div className="thumbnail">
              <div className="thumbnail-parent-container">
                <h3>Section 01</h3>
                <div className="thumbnail-content-container">
                  <div className="thumbnail-content-container-left">
                    <h2>Integrity</h2>
                    <div className="thumbnail-content-container-left-heading-underline"></div>
                    <div className="thumbnail-content-container-left-contents">Lorem ipsum dolor sit amet consectetur adipisicing elit. At aut nobis pariatur molestias ad neque blanditiis debitis, porro ratione natus quos quisquam ut dolor reprehenderit? Eius et sit nam quibusdam.</div>
                  </div>
                  <div className="thumbnail-content-container-right">
                    <img src={PlaceHolder2} alt="Place Holder"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="thumbnail">
            <div className="thumbnail-parent-container">
                <h3>Section 02</h3>
                <div className="thumbnail-content-container">
                  <div className="thumbnail-content-container-left">
                    <h2>Trust</h2>
                    <div className="thumbnail-content-container-left-heading-underline"></div>
                    <div className="thumbnail-content-container-left-contents">Lorem ipsum dolor sit amet consectetur adipisicing elit. At aut nobis pariatur molestias ad neque blanditiis debitis, porro ratione natus quos quisquam ut dolor reprehenderit? Eius et sit nam quibusdam.</div>
                  </div>
                  <div className="thumbnail-content-container-right">
                    <img src={PlaceHolder2} alt="Place Holder"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="thumbnail">
              <div className="thumbnail-parent-container">
                <h3>Section 03</h3>
                <div className="thumbnail-content-container">
                  <div className="thumbnail-content-container-left">
                    <h2>Responsibility</h2>
                    <div className="thumbnail-content-container-left-heading-underline"></div>
                    <div className="thumbnail-content-container-left-contents">Lorem ipsum dolor sit amet consectetur adipisicing elit. At aut nobis pariatur molestias ad neque blanditiis debitis, porro ratione natus quos quisquam ut dolor reprehenderit? Eius et sit nam quibusdam.</div>
                  </div>
                  <div className="thumbnail-content-container-right">
                    <img src={PlaceHolder2} alt="Place Holder"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="thumbnail">
              <div className="thumbnail-parent-container">
                <h3>Section 04</h3>
                <div className="thumbnail-content-container">
                  <div className="thumbnail-content-container-left">
                    <h2>Innovation</h2>
                    <div className="thumbnail-content-container-left-heading-underline"></div>
                    <div className="thumbnail-content-container-left-contents">Lorem ipsum dolor sit amet consectetur adipisicing elit. At aut nobis pariatur molestias ad neque blanditiis debitis, porro ratione natus quos quisquam ut dolor reprehenderit? Eius et sit nam quibusdam.</div>
                  </div>
                  <div className="thumbnail-content-container-right">
                    <img src={PlaceHolder2} alt="Place Holder"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="thumbnail">
              <div className="thumbnail-parent-container">
                <h3>Section 05</h3>
                <div className="thumbnail-content-container">
                  <div className="thumbnail-content-container-left">
                    <h2>Perseverance</h2>
                    <div className="thumbnail-content-container-left-heading-underline"></div>
                    <div className="thumbnail-content-container-left-contents">Lorem ipsum dolor sit amet consectetur adipisicing elit. At aut nobis pariatur molestias ad neque blanditiis debitis, porro ratione natus quos quisquam ut dolor reprehenderit? Eius et sit nam quibusdam.</div>
                  </div>
                  <div className="thumbnail-content-container-right">
                    <img src={PlaceHolder2} alt="Place Holder"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="thumbnail">
              <div className="thumbnail-parent-container">
                <h3>Section 06</h3>
                <div className="thumbnail-content-container">
                  <div className="thumbnail-content-container-left">
                    <h2>Empty</h2>
                    <div className="thumbnail-content-container-left-heading-underline"></div>
                    <div className="thumbnail-content-container-left-contents">Lorem ipsum dolor sit amet consectetur adipisicing elit. At aut nobis pariatur molestias ad neque blanditiis debitis, porro ratione natus quos quisquam ut dolor reprehenderit? Eius et sit nam quibusdam.</div>
                  </div>
                  <div className="thumbnail-content-container-right">
                    <img src={PlaceHolder2} alt="Place Holder"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.section>
      </div>
      <div ref={ghostRef} style={{ height: scrollRange - viewportW + window.innerHeight }} className="ghost" />
    </div>
  );
};

export default SmoothScroll;
