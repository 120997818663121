import React, { useEffect } from 'react'
import './HomeBanner.css'
import { GiHamburgerMenu } from "react-icons/gi";
import Logo2 from '../assest/logo-2.jpg'
import PlaceHolder2 from '../assest/place-holder-3.webp'

//AOS
import Aos from "aos";
import "aos/dist/aos.css";


function HomeBanner() {

  useEffect(() => {
    Aos.init({ duration: 1500,
      easing: 'ease', });
  }, []);



  // const horizontalContainerRef = useRef(null);
  // const [newHeight, setNewHeight] = useState(0);
  // const [newRotationDegree, setNewRotationDegree] = useState(0);
  // const [scrollPosition, setScrollPosition] = useState(0);
  // const [marginLeft, setMarginLeft] = useState(0);
  // const [newBoxTop, setNewBoxTop] = useState(58);
  // // const [newBoxTop, setNewBoxTop] = useState(69);
  // const flightAnimationRef = useRef(null);
  // const footerRef = useRef(null);
  // const lastScrollTopRef = useRef(0);

  useEffect(() => {
    Aos.init({
      duration: 1500,
      easing: 'ease',
      once: true
    });
  }, []);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentScrollTop = window.scrollY || document.documentElement.scrollTop;

  //     if (currentScrollTop < 800) {
  //       setScrollPosition(currentScrollTop);
  //     } else {
  //       setScrollPosition(801);
  //     }

  //     if (currentScrollTop > 805) {
  //       setMarginLeft(currentScrollTop - 1675);
  //     } else {
  //       setMarginLeft(0);
  //     }

  //     // Check scroll direction
  //     const isScrollingDown = currentScrollTop > lastScrollTopRef.current;
  //     lastScrollTopRef.current = currentScrollTop; // Update last scroll position

  //     // If scrolling down and in the right section, trigger smooth scroll
  //     // if (isScrollingDown && flightAnimationRef.current) {
  //     //   const rect = flightAnimationRef.current.getBoundingClientRect();
  //     //   const viewportHeight = window.innerHeight;

  //     //   if (rect.top <= viewportHeight && rect.bottom >= viewportHeight) {
  //     //     window.scrollTo({
  //     //       top: flightAnimationRef.current.offsetTop,
  //     //       behavior: 'smooth'
  //     //     });
  //     //   }
  //     // }
      
  //     if (isScrollingDown && footerRef.current) {
  //       const rect = footerRef.current.getBoundingClientRect();
  //       const viewportHeight = window.innerHeight;

  //       if (rect.top  + 40<= viewportHeight && rect.bottom >= viewportHeight) {
  //         window.scrollTo({
  //           top: footerRef.current.offsetTop,
  //           behavior: 'smooth'
  //         });
  //       }
  //     }

  //     // if (!isScrollingDown && footerRef.current) {
  //     //   const rect = footerRef.current.getBoundingClientRect();
  //     //   const viewportHeight = window.innerHeight;

  //     //   if (rect.top <= viewportHeight && rect.bottom >= viewportHeight) {
  //     //     window.scrollTo({
  //     //       top: flightAnimationRef.current.offsetTop,
  //     //       behavior: 'smooth'
  //     //     });
  //     //   }
  //     // }
  //   };

  //   const height = 100;
  //   if(scrollPosition == 0){
  //     setNewHeight(45);
  //     setNewRotationDegree(0);
  //   }else if(scrollPosition <= 100){
  //     setNewHeight(100);
  //     setNewRotationDegree(scrollPosition / 10)
  //     setMarginLeft(0);
  //   }else{
  //     setNewHeight(height - scrollPosition / 10);
  //     setNewRotationDegree(scrollPosition / 5)
  //   }

  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [scrollPosition, marginLeft]);

  // useEffect(() => {
  //   if (marginLeft >= 0 && marginLeft < 15) {
  //     document.body.classList.add('no-scroll');
  //     setTimeout(() => {
  //       document.body.classList.remove('no-scroll');
  //     }, 1500);
  //   }
  // }, [marginLeft]);

  // useEffect(() => {
  //   const handleHorizontalScroll = (event) => {
  //     const horizontalContainer = horizontalContainerRef.current;
  //     const verticalScrollTop = window.pageYOffset || document.documentElement.scrollTop;

  //     if (horizontalContainer && verticalScrollTop >= horizontalContainer.offsetTop && verticalScrollTop < horizontalContainer.offsetTop + horizontalContainer.offsetHeight) {
  //       event.preventDefault();
  //       horizontalContainer.scrollLeft += event.deltaY;
  //     }
  //   };

  //   window.addEventListener('scroll', handleHorizontalScroll, { passive: false });

  //   return () => {
  //     window.removeEventListener('scroll', handleHorizontalScroll);
  //   };
  // }, []);

  // // New calculations  
  
  // const left = (newHeight - 20) / 2;
  // const newBoxTopVariable = newBoxTop - scrollPosition / 50;


  return (
    <div className='foo-container'>
        <div className="foo">
          <div className='foo-content'>
            <div className="foo-header-contents">
              <div className="foo-header-logo-conatiner">
                <img src={Logo2} alt='' data-aos='fade-down' data-aos-delay='1500'/>
              </div>
              <div className='nav-bar-icon'><GiHamburgerMenu size={45} data-aos='fade-down' data-aos-delay='2500'/></div>
            </div>
            <div className="foo-content-body">
              <div className="foo-content-body-left" data-aos='fade-right' data-aos-delay='2000'>
                <h1>Diversity, <br />Equity <br />Inclusivity</h1>

                {/* <div className='new-box'
                  style={{
                    transform: `rotate(-${scrollPosition >= 800 ? 180 : newRotationDegree}deg)`,
                    width: `${marginLeft >= 100 ? newHeight + 20 : newHeight}px`,
                    height: `${newHeight}px`,
                    
                    zIndex: '100',
                    top: `${67}%`,
                    marginLeft: `${170}px`,
                    border: `${marginLeft >= 100 ? '2.5px solid darkgray' : 'none'}`,
                    scale: `${marginLeft >= 100 ? 80 : 1}`,
                    background:`${marginLeft >= 100 ? 'white' : 'transparent'}`,
                    transition : `${scrollPosition <= 100 ? '0.5s ease' : ''}`
                  }}
                >
                  <div className='new-box-horizontel' style={{ height: `${newHeight <= 20 ? 20 : newHeight}px`, left: `${left <= 0 ? 0 : left}px`, display: `${marginLeft >= 100 ? 'none' : 'block'}`, transition : `${scrollPosition <= 100 ? '0.5s ease' : 'none'}` }}></div>
                  <div className='new-box-vertical' style={{ width: `${newHeight <= 20 ? 40 : newHeight}px`, top: `${left <= 0 ? 0 : left}px`, display: `${marginLeft >= 100 ? 'none' : 'block'}`, transition : `${scrollPosition <= 100 ? '0.5s ease' : 'none'}`, }}></div>
                </div> */}
              </div>

              <div className="foo-content-body-right">
                <img src={PlaceHolder2} alt="Place Holder" data-aos='fade-up' data-aos-delay='1500'/>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default HomeBanner
