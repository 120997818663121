import React from "react";
import "./Footer.css";
import Hirdaramani from "../assest/logo-2.jpg";
// import FooterVideo from '../assest/atla-main-globe-black.mp4'

function Footer() {
  return (
    <>
      <div className="footer-container">
        <div className="footer-container-01">
          <div className="footer-container-01-image">
            <img src={Hirdaramani} alt="Hirdaramani" />
          </div>
          <p>Building a future with safe AI.</p>
          <a href="/" className="footer-container-01-btn">
            Sign Up
          </a>
        </div>
        <div className="footer-container-02">
          <div className="footer-container-02-lists-container">
            <div className="footer-container-02-lists-container-01">
              <p>Quick Links</p>
              <ul>
                <li>
                  <a
                    href="https://www.sunshineholdings.lk/our-story/history"
                    target="_blank"
                    rel="noreferrer"
                  >
                    History
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.sunshineholdings.lk/our-businesses/healthcare"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Healthcare
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.sunshineholdings.lk/investor/financial-reports"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Financial Reports
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.sunshineholdings.lk/our-story/governance"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Governance
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-container-02-lists-container-02">
              <ul>
                <li>
                  <a
                    href="https://www.sunshineholdings.lk/investor/news"
                    target="_blank"
                    rel="noreferrer"
                  >
                    News
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.sunshineholdings.lk/opportunities/join-the-team"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Join the Team
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.sunshineholdings.lk/terms-conditions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.sunshineholdings.lk/get-in-touch"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <div className="footer-container-03">
        <video loop autoPlay muted width={500} height={500}>
            <source src={FooterVideo} type='video/webm'/>
        </video>
        <div className="footer-find-us">

        </div>
      </div> */}
      </div>
      <div className="footer-copy-right-section">
        <p>&copy; 2024 Copyright Hirdaramani . All Rights Reserved</p>
      </div>
    </>
  );
}

export default Footer;
